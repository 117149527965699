<template>
  <div class="support">
    <div class="banner">
        <div class="banner_titile">
            <span>HOLOWITS Support</span>
        </div>
        <div class="t-nav">
            <div v-for="(item, index) in links" :key="item.name" class="target" @click="toPage(item.path)" @mouseenter="active(item)" @mouseout="unactive(item)">
                <div class="icon">
                    <img :src="domainUrl + item.icon" alt="" style="display: block;"/>
                    <img :src="domainUrl + item.activeIcon" alt="" style="display: none;"/>
                </div>
                <div class="name">{{ item.name }} <span v-if="index === 0" class="beta">New</span></div>
                <div v-if="index === 0" class="line"></div>
            </div>
        </div>
    </div>
    <div class="text-links">
        <div class="link-list" v-for="link in Object.keys(this.textLinks)" :key="link">
            <div class="link-head">
                <img :src="textLinks[link].icon" alt="" width="40px" height="40px">
                {{ textLinks[link].name }}
            </div>
            <div class="link-body">
                <ul>
                    <li v-for="item in textLinks[link].links" :key="item.name"><a @click="toPage(item.path)">{{ item.name }}</a></li>
                </ul>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { getUserInfoByToken } from "@/api/userApi";
import db from "@/utils/localstorage";
import {MessageBox} from 'element-ui';
export default {
    name: 'SupportIndex',
    data() {
        return {
            hasRight: false,
            links: [
                {
                    icon: 'support/申请单.png',
                    activeIcon: 'support/申请单.png',
                    // activeIcon: 'support/wiki_red.png',
                    name: 'Wiki',
                    path: '/holowitswiki',
                    active: false
                },
                {
                    icon: 'support/firmware.png',
                    // activeIcon: 'support/firmware.png',
                    activeIcon: 'support/firmware_red.png',
                    name: 'Firmware',
                    path: '/firmware',
                    active: false
                },
                {
                    icon: 'support/software.png',
                    // activeIcon: 'support/software.png',
                    activeIcon: 'support/software_red.png',
                    name: 'Software',
                    path: '/soft-download',
                    active: false
                },
                {
                    icon: 'support/knowledge.png',
                    // activeIcon: 'support/knowledge.png',
                    activeIcon: 'support/knowledge_red.png',
                    name: 'Knowledge',
                    path: '/knowledge',
                    active: false
                },
                {
                    icon: 'support/e-learning.png',
                    // activeIcon: 'support/e-learning.png',
                    activeIcon: 'support/e-learning_red.png',
                    name: 'E-learning',
                    path: '/training',
                    active: false
                },
                {
                    icon: 'support/policy_4.png',
                    // activeIcon: 'support/policy_4.png',
                    activeIcon: 'support/terms_red.png',
                    name: 'Policy,Terms',
                    path: '/terms',
                    active: false
                },
                {
                    icon: 'support/gonggao_white.png',
                    activeIcon: 'support/gonggao_red.png',
                    name: 'Announcement',
                    path: '/announcement',
                    active: false
                },
            ],
            textLinks: {
                1 : {
                    icon: 'https://resources.holowits.com.sg/support/guideline_3.png',
                    name: 'Operation Guideline',
                    links: [{
                        name: 'AI Camera',
                        path: '/holowitswiki/AI Camera'
                    }, {
                        name: 'Video Intelligent Platform',
                        path: '/holowitswiki/Video Intelligent Platform'
                    }, {
                        name: 'IP Camera & Analog Camera',
                        path: '/holowitswiki/IP Camera & Analog Camera'
                    }, {
                        name: 'NVR & XVR',
                        path: '/holowitswiki/NVR & XVR'
                    }, {
                        name: 'Software',
                        path: '/holowitswiki/Software'
                    }, {
                        name: 'Cloud Service',
                        path: '/holowitswiki/Cloud Service'
                    }, {
                        name: 'Solution',
                        path: '/holowitswiki/Solution'
                    }, {
                        name: 'Other',
                        path: '/holowitswiki/Other'
                    }]
                },
                // 2 : {
                //     icon: 'https://resources.holowits.com/support/document_3.png',
                //     name: 'Document & Manual',
                //     links: [{
                //         name: 'AI Cameras',
                //         path: ''
                //     }, {
                //         name: 'Video Intelligent Platform',
                //         path: ''
                //     }, {
                //         name: 'IP Camera & Analog Camera',
                //         path: ''
                //     }, {
                //         name: 'NVR & XVR',
                //         path: ''
                //     }, {
                //         name: 'VMS',
                //         path: ''
                //     }, {
                //         name: 'Cloud Service',
                //         path: ''
                //     }, {
                //         name: 'Other',
                //         path: ''
                //     }]
                // },
                3 : {
                    icon: 'https://resources.holowits.com.sg/support/tools_5.png',
                    name: 'Tools',
                    links: [{
                        name: 'Calculate Bandwidth',
                        path: '/tools/CB'
                    }, {
                        name: 'Calculate Recording Capacity',
                        path: '/tools/CRC'
                    }, {
                        name: 'NVR Access',
                        path: '/tools/NA'
                    }, {
                        name: `Calculate Camera's Detection Distance`,
                        path: '/tools/CCDD'
                    }, {
                        name: `IP System Design Tool`,
                        path: '/tools/IP'
                    }, {
                        name: `MD5 Checker`,
                        path: '/tools/md5'
                    }]
                },
                // 4 : {
                //     icon: 'https://resources.holowits.com/support/policy_3.png',
                //     name: 'Policy,teams',
                //     links: [{
                //         name: 'HOLOWITS Product Warranty Policy',
                //         path: '/warrantypolicy'
                //     }, {
                //         name: 'HOLOWITS RMA Policy',
                //         path: '/terms/rma'
                //     }, ]
                // },
                // 5 : {
                //     icon: 'https://resources.holowits.com/support/certification_1.png',
                //     name: 'Certification',
                //     links: [{
                //         name: 'EU Declaration of Conformity',
                //         path: '/product-certification'
                //     }]
                // },
            }
        }
    },
    mounted() {
        this.getUserInfoByToken()
    },
    methods: {
        async getUserInfoByToken() {
            let userInfo = db.get('CLIENT_USER', '');
            if (userInfo) {
                this.hasRight = true;
                // const {data: {data, isSuccess}} = await getUserInfoByToken({
                //     id: userInfo.id
                // })
                // if (isSuccess) {
                //     if (data.roleId === '1') {
                //         this.hasRight = true;
                //     }
                // }
            }
        },
        toPage(path) {
            if (path.includes('/holowitswiki')) {
                if (this.hasRight === false) {
                    // const h = this.$createElement;
                    // MessageBox.alert('',{
                    //     title: 'tips',
                    //     message: h('div', null, [
                    //         h('p', null, 'Wiki is currently in the internal testing phase and is only accessible to employees.'),
                    //         h('p', null, 'We will officially release it to the public in early October.'),
                    //         h('p', null, 'Thank you for your understanding and support!')
                    //     ]),
                    // })
                    this.$router.push({path: '/login'})
                    return false;
                }
            }
            if (path) {
                this.$router.push({path: path})
            }
        },
        active(item) {
            item.active = true
        },
        unactive(item) {
            item.active = false
        }
    }
}
</script>

<style lang="less" scoped>
.support {
    min-height: inherit;
}

.text-links {
    padding-top: 50px;
    margin: auto;
    width: 950px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 500px;
    .link-list {
        min-width: 220px;
        & + .link-list {
            margin-left: 60px;
        }
        .link-head {
            font-weight: 600;
            font-size: 16px;
        }
        .link-body {
            margin-left: 48px;
            font-size: 14px;
            margin-top: 10px;
            li {
                height: 40px;
                line-height: 40px;
                &:hover {
                    cursor: pointer;
                    // padding-bottom: 2px;
                    border-bottom: 1px solid #c7000b;
                }
            }
        }
    }
}

.target {
    min-width: 80px;
    height: 100%;
    cursor: pointer;
    &:hover {
        .icon {
            width: 70px;
            height: 70px;
            img:nth-child(1) {
                display: none !important;
            }
            img:nth-child(2) {
                display: block !important;
            }
        }
        .name {
            color: rgb(199, 0, 11);
        }
    }

    &:nth-child(1) {
        position: relative;
        .icon {
            width: 70px;
            height: 70px;
            img:nth-child(1) {
                display: none !important;
            }
            img:nth-child(2) {
                display: block !important;
            }
        }
        .name {
            color: rgb(199, 0, 11);
            display: flex;
            align-items: center;
            justify-content: space-between;
            .beta {
                margin-left: 5px;
                background-color: rgba(255, 174, 0, 0.774);
                color: #000000;
                font-size: 12px;
                border-radius: 5px;
                padding: 2px 5px;
            }
        }
        .line {
            height: 5px;
            width: 100%;
            background-color: rgb(197, 0, 11);
            position: absolute;
            bottom: 1px;
        }
    }

    .icon {
        transition: all 0.2s;
        width: 60px;
        height: 60px;
        margin: 0 auto;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .name {
        text-align: center;
        font-size: 18px;
    }
}
.target+.target {
    margin-left: 90px;
}

.banner {
  height: 420px;
  background-image: url('https://resources.holowits.com/support/banner_2.png');
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  color: #FFFFFF;
//   padding-top: 120px;
  position: relative;
  
  .banner_titile {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        font-size: 42px;
        text-shadow: 2px 2px 9px #00000099;
    }
  }
}
.t-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    background-color: #00000075;
    // padding: 10px 0;
    .target {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
</style>